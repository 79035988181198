export enum ConnectorType {
  J1772 = 'J1772',
  DUAL_J1772 = 'DUAL_J1772',
  CCS = 'CCS',
  DUAL_CCS = 'DUAL_CCS',
  CHADEMO = 'CHAdeMO',
  CCS_CHADEMO = 'CCS + CHAdeMO',
  TYPE_2 = 'Type 2',
  TYPE_2_CCS = 'Type 2 CCS',
  TYPE_2_CHADEMO_TYPE_2_CCS = 'Type 2/CHAdeMO/Type 2 CCS',
  TESLA = 'Tesla',
  NACS = 'NACS',
}
