import { i18n } from '../../../services/translation/i18n';
import { PricingType } from '../../../stores/types/price.interface';

export const sessionPricingType = (type: string) => {
  let response;
  switch (type) {
    case PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME:
      response = 'hr ' + i18n.t('charging');
      break;
    case PricingType.BILLED_BY_TIME_PLUGGED_IN:
      response = 'hr ' + i18n.t('receipt_plugged_in');
      break;
    case PricingType.BILLED_BY_KWH:
      response = 'kWh';
      break;
    case PricingType.IDLE:
      response = 'min';
      break;
    default:
      response = i18n.t('free');
      break;
  }
  return response;
};

export const isFreePricing = (type: string) => {
  return (
    type !== PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME &&
    type !== PricingType.BILLED_BY_TIME_PLUGGED_IN &&
    type !== PricingType.BILLED_BY_KWH
  );
};

export const getAbbreviation = (input: string) => {
  const matches = {
    'J1772': 'J1772',
    'CCS': 'CCS',
    'CHAdeMO': 'CHA',
    'Type 2': 'Type 2',
    'Type 2 CCS': 'CCS',
    'Tesla': 'NACS',
    'NACS': 'NACS',
  };

  return matches[input] || input;
};
