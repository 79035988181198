import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuth } from '../../hooks/useAuth';
import { getTheme } from '../../stores/selectors/theme.selector';
import { RuleTypes } from '../../stores/types/price.interface';
import CustomButton from '../react-native-material-wrapper/button.component';
import { BUTTON_STATUS } from './charger.component';
import { useFetchListOfPayMethodsQuery } from '../../services/payment.api';
import { ConnectorStatus } from '../../types/connector-status.enum';
import { ChargerDtoV2, IdleFeesStatus, Session } from './dto/charger.dto';
import { useUserPreference } from '../../hooks/useUserPreference';
import { ErrorRounded, Info, InfoRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../types/routes.enum';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { ColorType } from '../../assets/colors/colors.g';
import CustomDrawer from '../ui-components/drawer.component';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '425px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
    '& button': {
      width: '100% !important',
    },
  },

  bank: {
    backgroundColor: '#F44336',
    color: 'white',
    padding: '8px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'Inter',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '56px',
  },
  addcard: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none !important',
    float: 'right',
    fontSize: '14px',
    fontWeight: 600,
  },
  cardLink: {
    display: 'inline-block',
    width: '15%',
    verticalAlign: 'bottom',
  },
  cardWrapper: {
    display: 'inline-block',
    width: '80%',
  },
  buttonWrapper: {
    padding: '20px 24px',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  buttonWrapperInfo: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    '& .info-text': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#202223',
    },
  },
  buttonStyles: {
    color: '#ffffff',
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  addPaymentContainer: {
    padding: '16px 24px 24px 24px',
    background: '#EBF8FF',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
  },
  addPaymentLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addPaymentRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addPaymentTypography: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#202223',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  sessionErrorMessage: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#202223',
    alignItems: 'center',
  },
  sessionErrorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#FDEDED',
    padding: '16px 24px 24px 24px',
    boxSizing: 'border-box',
    filter: 'drop-shadow(0px -4px 16px rgba(0, 0, 0, 0.10))',
  },
  startSession: {
    display: 'flex',
    height: '24px',
    gap: '8px',
    alignItems: 'center',
  },
  newButtonWrapper: {
    padding: '16px 24px',
    backgroundColor: '#FFF9EB',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  drawerWrapper: {
    display: 'flex',
    width: '375px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  drawerHeading: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    marginBottom: '8px',
  },
  drawerSubHeading: {
    color: '#6B7684',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    marginBottom: '24px',
  },
  drawerCancelButton: {
    color: '#202223',
    bottom: 0,
    position: 'sticky',
    height: 48,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    borderRadius: 4,
    textTransform: 'inherit',
    border: '1px solid #D1D6DB',
    background: '#FFF',
    width: '100%',
    '&:hover': {
      boxShadow: 'none',
      border: '1px solid #D1D6DB',
    },
  },
}));

/**
 * Sticky buttons at the bottom of the page
 *
 */

interface ChargerButtonProp {
  startCharge: Function;
  charger: ChargerDtoV2;
  stopCharge: Function;
  buttonStatus: BUTTON_STATUS;
  loading: boolean;
  showTechView: boolean;
  isChargingBySelf: boolean;
  selectedConnectorId: string | null;
  activeSession: Session;
}
const ChargerButtons = ({
  startCharge,
  charger,
  stopCharge,
  buttonStatus,
  loading,
  showTechView = false,
  isChargingBySelf,
  selectedConnectorId,
  activeSession,
}: ChargerButtonProp) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const auth = useAuth();

  const theme = useSelector(getTheme);
  const { data: payment } = useFetchListOfPayMethodsQuery();
  const { userIsNotSelectedForPayGo } = useUserPreference();
  const navigate = useNavigate();
  const { allowSinglePortReliability, allowMultiplePortReliability } =
    useUserPreference();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const needBankCard = useMemo(() => {
    return (
      !payment?.hasPaymentMethod &&
      charger &&
      charger.priceSchedule.type !== RuleTypes.FREE
    );
  }, [charger, payment]);

  const renderLoading = () => {
    return (
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: theme?.brand_0,
          }}
          disabled
        >
          <LoadingDots />
        </CustomButton>
      </div>
    );
  };

  const renderAddPaymentMethod = () => {
    return (
      <div className={classes.addPaymentContainer}>
        <div
          style={{
            paddingBottom: '16px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className={classes.addPaymentLeft}>
            <InfoRounded style={{ color: theme.brand_3 }} />
          </div>
          <div className={classes.addPaymentRight}>
            <Typography className={classes.addPaymentTypography}>
              {i18n.t('add_payment_method_start_charging')}
            </Typography>
          </div>
        </div>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{ backgroundColor: theme.brand_2, padding: '12px 16px' }}
          // search param is added in order to redirect user directly to add payment page
          onClick={() =>
            navigate(
              `/app/${window.domainName}/${ROUTES.PAYMENTS}?view=add${charger?.name ? `&charger=${charger.name}` : ''
              }`,
            )
          }
        >
          {i18n.t('payment_method_add')}
        </CustomButton>
      </div>
    );
  };

  const renderSelectPortsButton = () => {
    return (
      <div className={classes.buttonWrapper}>
        <div className={classes.buttonWrapperInfo}>
          <Info style={{ color: '#6B7684' }} />
          <div className='info-text'>{i18n.t('select_port_tip')}</div>
        </div>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: '#F9FAFB',
            color: '#D1D6DB',
          }}
          disabled
        >
          {i18n.t('continue')}
        </CustomButton>
      </div>
    );
  };

  const renderStartSessionDrawer = () => {
    const disabled =
      needBankCard || (charger?.maintenanceFlag && !showTechView);
    return (
      <CustomDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
        <div className={classes.drawerWrapper}>
          <Typography className={classes.drawerHeading}>
            {i18n.t('start_session_drawer_heading')}
          </Typography>
          <Typography className={classes.drawerSubHeading}>
            {i18n.t('start_session_drawer_sub_heading')}
          </Typography>
          <CustomButton
            variant='contained'
            className={classes.buttonStyles}
            style={{
              width: '100%',
              marginBottom: '16px',
              backgroundColor: disabled
                ? ColorType.GREY0
                : theme.colorPalettes.primary,
            }}
            onClick={startCharge}
            disabled={disabled}
            disableElevation
          >
            {i18n.t('vehicle_not_plugged_in_text')}
          </CustomButton>
          <CustomButton
            variant='outlined'
            className={classes.drawerCancelButton}
            onClick={() => setIsDrawerOpen(false)}
          >
            {i18n.t('cancel')}
          </CustomButton>
        </div>
      </CustomDrawer>
    );
  };

  const renderStartSessionButton = () => {
    const disabled =
      needBankCard || (charger?.maintenanceFlag && !showTechView);
    return (
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: disabled
              ? ColorType.GREY0
              : theme.colorPalettes.primary,
          }}
          onClick={startCharge}
          disabled={disabled}
          disableElevation
        >
          {i18n.t('start_session')}
        </CustomButton>
      </div>
    );
  };

  const renderFeaturedSessionButton = () => {
    const disabled =
      needBankCard || (charger?.maintenanceFlag && !showTechView);
    return (
      <div className={classes.newButtonWrapper}>
        <div className={classes.buttonWrapperInfo}>
          <Info style={{ color: '#FFB300' }} />
          <div className='info-text'>
            {i18n.t('start_session_button_warning')}
          </div>
        </div>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: disabled
              ? ColorType.GREY0
              : theme.colorPalettes.primary,
          }}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          disabled={disabled}
          disableElevation
        >
          {i18n.t('continue')}
        </CustomButton>
      </div>
    );
  };

  // For MVP of PayGo :
  // userIsNotSelectedForPayGo : false ---> user will get error msg for failed session start
  const renderStart = () => {
    // Add payment page should be on top of Start Session / Select Port btn
    if (needBankCard && payment) {
      return <div>{renderAddPaymentMethod()}</div>;
    }
    return (
      <div>
        {selectedConnectorId || charger.ports?.length === 1
          ? allowSinglePortReliability || allowMultiplePortReliability
            ? renderFeaturedSessionButton()
            : renderStartSessionButton()
          : renderSelectPortsButton()}
      </div>
    );
  };

  const renderStop = () => {
    const unavailableCharger = charger.ports.every(
      (ele) =>
        ele.statusWithoutMaintenance?.toLowerCase() === // statusWithoutMaintenance is optional now
        ConnectorStatus.UNAVAILABLE.toLowerCase() ||
        ele.status.toLowerCase() === ConnectorStatus.UNKNOWN.toLowerCase(),
    );
    return (
      <div className={classes.buttonWrapper}>
        <CustomButton
          variant='contained'
          className={classes.buttonStyles}
          style={{
            backgroundColor: '#F44336',
          }}
          onClick={stopCharge}
          disabled={isChargingBySelf && unavailableCharger}
        >
          {i18n.t('stop')}
        </CustomButton>
      </div>
    );
  };

  if (
    !auth.isLogined ||
    !payment ||
    // driver login but don't have access
    !charger.userHasAccess ||
    // no available chargers and in session charging by others, not me.
    (!charger.isAnyConnectorAvailable && !isChargingBySelf)
  ) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        {loading
          ? renderLoading()
          : buttonStatus === BUTTON_STATUS.IN_SESSION &&
            activeSession.idleFeeStatus !== IdleFeesStatus.IMPOSED
            ? renderStop()
            : buttonStatus !== BUTTON_STATUS.IN_SESSION
              ? renderStart()
              : null}
      </div>
      {renderStartSessionDrawer()}
    </>
  );
};

export default ChargerButtons;
